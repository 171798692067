<template>
  <div>
    <h2 class="profile">Comment Management List</h2>
    <div class="incomeList">
      <el-form label-width="68px" :inline="true" ref="queryFormRef" :model="queryParams">
        <el-form-item label="Username" prop="username">
          <el-input v-model="queryParams.username" placeholder="" clearable />
        </el-form-item>
        <el-form-item label="Nickname" prop="nickname">
          <el-input v-model="queryParams.nickname" placeholder="" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="handleQuery">search</el-button>
          <el-button size="small" @click="resetQuery(queryFormRef)">reset</el-button>
        </el-form-item>
      </el-form>
      <hr />

      <el-table class="pc" :data="commentData" style="width: 85%">
        <el-table-column
          prop="user.username"
          label="username"
          width="180"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="user.nickname"
          label="nikename"
          width="130"
          align="center"
        />
        <el-table-column prop="rating" label="rating" width="100" align="center" />
        <el-table-column prop="comment" label="comment" width="100" align="center" />
        <el-table-column prop="createtime" label="createtime " width="120" align="center">
          <template #default="scope">
            <span v-if="scope.row.createtime">{{
              timeFormat(scope.row.createtime)
            }}</span>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="operate">
          <template #default="scope">
            <el-button
              size="small"
              type="primary"
              @click="handleDelete(scope.$index, scope.row)"
              >Delete</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-list pc">
      <el-pagination
        v-show="state.total > 0"
        :total="state.total"
        :page="queryParams.pageNum"
        :limit="queryParams.pageSize"
        @pagination="getMessList"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 移动端 -->
    <div v-if="commentData && commentData.length > 0" class="mobile list">
      <div class="card" v-for="(item, index) in commentData" :key="item.id">
        <div>
          username: <span>{{ item.user.username }}</span>
        </div>
        <div>
          nikename: <span>{{ item.user.nickname }}</span>
        </div>
        <div>
          rating: <span>{{ item.rating }}</span>
        </div>
        <div>
          comment: <span>{{ item.comment }}</span>
        </div>
        <div>
          createtime:
          <span v-if="item.createtime">{{ timeFormat(item.createtime) }}</span>
          <span v-else>--</span>
        </div>
        <div>
          operate:
          <span
            ><el-button size="small" type="primary" @click="handleDelete(index, item)"
              >Delete</el-button
            ></span
          >
        </div>
      </div>
    </div>
    <div v-else class="mobile noData">No Data</div>

    <div v-if="commentData && commentData.length > 0" class="mobile paging">
      <el-pagination
        small
        v-show="state.total > 0"
        :total="state.total"
        :page="queryParams.pageNum"
        :limit="queryParams.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :pager-count="8"
      />
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, onMounted } from "vue";
import { getMessageList, delMessage } from "@/api/center.js";
import { ElMessage, ElMessageBox } from "element-plus";
import dayjs from "dayjs";
const props = defineProps({
  consultantId: {
    type: Number || "",
    default: "",
  },
});
const commentData = ref([]);
const queryParams = ref({
  username: "",
  nickname: "",
  pageNum: 1,
  pageSize: 10,
});
const queryFormRef = ref({});
const state = reactive({
  total: "",
});

onMounted(() => {
  getMessList();
});
//重置
const resetQuery = (formEl) => {
  if (!formEl) return;
  formEl.resetFields();
  getMessList();
};
//搜索
const handleQuery = () => {
  queryParams.value.pageNum = 1;
  getMessList();
};

const getMessList = () => {
  console.log(queryParams.value, "queryParams");
  queryParams.value.consultantId = props.consultantId;
  getMessageList(queryParams.value).then((res) => {
    if (res.code == 200) {
      console.log(res, "信息");
      commentData.value = res.rows;
      state.total = res.total;
    }
  });
};
const handleDelete = (index, row) => {
  ElMessageBox.confirm("Are you sure you want to delete it?", {
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    type: "warning",
  })
    .then(() => {
      ElMessage({
        type: "success",
        message: "Successfully deleted",
      });

      console.log(index, row.id);
      delMessage(row.id).then((res) => {
        if (res.code == 200) {
          console.log(res);
          getMessList();
        }
      });
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "Delete canceled",
      });
    });
};
//时间转换
const timeFormat = (val) => {
  return dayjs(String(val)).format("YYYY-MM-DD");
};

const handleSizeChange = (val) => {
  queryParams.value.pageNum = val;
  getMessList();
};
const handleCurrentChange = (val) => {
  queryParams.value.pageNum = val;
  getMessList();
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 769px) {
  .mobile {
    display: none;
  }

  .incomeList {
    padding: 30px 20px;
  }

  .profile {
    line-height: 50px;
    border-bottom: 1px solid #c2bcbc;
    padding-left: 20px;
    color: #02758a;
  }

  .page-list {
    display: flex;
    justify-content: right;
    margin-right: 200px;
  }
}

//移动端
@media screen and (max-width: 768px) {
  .noData {
    text-align: center;
    font-size: 20px;
  }
  .incomeList {
    padding: 30px 20px;
  }

  .profile {
    line-height: 50px;
    border-bottom: 1px solid #c2bcbc;
    padding-left: 20px;
    color: #02758a;
  }

  .page-list {
    display: flex;
    justify-content: right;
    margin-right: 200px;
  }
  .pc {
    display: none;
  }

  .list {
    .card {
      background: #eee;
      margin-top: 20px;
      padding: 10px;
      border-radius: 6px;

      div {
        line-height: 25px;
        font-size: 18px;

        span {
          color: red;
          margin-left: 10px;
          font-size: 15px;
        }
      }
    }
  }

  .paging {
    margin-top: 20px !important;
  }
}
</style>
